import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { IButtonIconProps } from './ButtonIcon.type';
import styles from './ButtonIcon.module.css';

const ButtonIcon: React.FC<IButtonIconProps> = ({
  onClick,
  content,
  level,
  selectedLevel,
  disabled
}: IButtonIconProps) => {
  const handleOnClick = useCallback(() => {
    if (selectedLevel !== level) {
      onClick(level);
    }
  }, [level, onClick, selectedLevel]);

  return (
    <div className={`${styles.rulesListItem} rulesListItemGlobal`}>
      <Button
        variant="secondary"
        disabled={disabled}
        className={classNames(styles.rulesListItemBg, {
          [styles.active]: selectedLevel === level,
          buttonIconActiveGlobal: selectedLevel === level
        })}
        onClick={handleOnClick}
      >
        {content}
      </Button>
      <div className={styles.rulesListItemText}>{level.split(',').join(', ')}</div>
    </div>
  );
};

export default ButtonIcon;
