import { handleActions } from 'redux-actions';
import { SUCCESS, PENDING, FAILURE } from '../constants/store.constants';
import { defaultRequestStatus } from './fetch.types';
import { IVerifyMemberState } from './verifyMember.types';
import {
  REMAINING_MEMBER_FAILURE,
  REMAINING_MEMBER_REQUEST,
  REMAINING_MEMBER_SUCCESS,
  VERIFY_MEMBER_ERROR_RESET,
  VERIFY_MEMBER_FAILURE,
  VERIFY_MEMBER_REQUEST,
  VERIFY_MEMBER_SUCCESS
} from '../actions/verifyMember.action';

const defaultState: IVerifyMemberState = {
  request: defaultRequestStatus,
  remainingRequest: defaultRequestStatus,
  remainingCount: 0
};

const reduces = handleActions(
  {
    [VERIFY_MEMBER_REQUEST]: state => ({
      ...state,
      request: { status: PENDING, error: null }
    }),
    [VERIFY_MEMBER_FAILURE]: (state, action) => ({
      ...state,
      request: { status: FAILURE, error: action.payload }
    }),
    [VERIFY_MEMBER_SUCCESS]: state => ({
      ...state,
      request: { status: SUCCESS, error: null }
    }),
    [VERIFY_MEMBER_ERROR_RESET]: state => ({
      ...state,
      request: defaultRequestStatus
    }),
    [REMAINING_MEMBER_REQUEST]: state => ({
      ...state,
      remainingRequest: { status: PENDING, error: null }
    }),
    [REMAINING_MEMBER_FAILURE]: (state, action) => ({
      ...state,
      remainingRequest: { status: FAILURE, error: action.payload }
    }),
    [REMAINING_MEMBER_SUCCESS]: (state, action) => ({
      ...state,
      remainingRequest: { status: SUCCESS, error: null },
      remainingCount: action?.payload?.count || 0
    })
  },
  defaultState
);

export default reduces;
