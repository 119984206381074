import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { ILoginModalProps } from './LoginModal.types';
import { USER_EMAIL, USER_PASSWORD } from '../../constants/storageKeys';
import { usePrevious } from '../../hooks/usePrevious';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import styles from './LoginModal.module.css';

const LoginModal: React.FC<ILoginModalProps> = ({
  show,
  onHide,
  onRecovery,
  login,
  error,
  isLoading,
  isUserLogin
}: ILoginModalProps) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(() => {
    return Cookies.getJSON(USER_EMAIL) || '';
  });
  const [password, setPassword] = useState(() => {
    return Cookies.getJSON(USER_PASSWORD) ? Cookies.getJSON(USER_PASSWORD).toString() : '';
  });
  const [saveCookieCheckBox, setSavCookiesCheckBox] = useState<boolean>(() => {
    return !!Cookies.getJSON(USER_EMAIL);
  });
  const prevUserLogin = usePrevious(isUserLogin);

  useEffect(() => {
    // clear the modal fields only after logout
    if (!isUserLogin && prevUserLogin && !Cookies.getJSON(USER_EMAIL)) {
      setSavCookiesCheckBox(false);
      setPassword('');
      setEmail('');
    }
  }, [isUserLogin, prevUserLogin]);

  const hasEmptyField = !email || !password;

  const onEmailChange = useCallback(
    e => {
      setEmail(e.target.value);
    },
    [setEmail]
  );

  const onPasswordChange = useCallback(
    e => {
      setPassword(e.target.value);
    },
    [setPassword]
  );

  const onCheckBoxChange = useCallback(({ target: { checked } }) => {
    setSavCookiesCheckBox(checked);
  }, []);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      login(email, password, saveCookieCheckBox);
    },
    [login, email, password, saveCookieCheckBox]
  );

  const handleRecoveryLinkClick = useCallback(
    e => {
      e.preventDefault();
      onRecovery();
    },
    [onRecovery]
  );

  return (
    <Modal className="modalLogin" animation show={show} onHide={onHide} size="sm">
      <Modal.Header closeButton>
        <Modal.Title>{t('loginModal.title')}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group controlId="formBasicEmail">
            <Form.Control
              name="email"
              type="email"
              placeholder={t('loginModal.emailPlaceholder')}
              value={email}
              onChange={onEmailChange}
              autoComplete="email"
            />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Control
              name="password"
              type="password"
              placeholder={t('loginModal.passwordPlaceholder')}
              value={password}
              onChange={onPasswordChange}
              autoComplete="password"
            />
          </Form.Group>
          <Form.Group controlId="formBasicSaveCookies">
            <Form.Check
              custom
              name="saveCookies"
              id="saveCookies"
              label={t('loginModal.storeLoginData')}
              checked={saveCookieCheckBox}
              onChange={onCheckBoxChange}
            />
          </Form.Group>
          {error && <p>{error}</p>}
          <Form.Group className={styles.formLinkBox}>
            <a href="/" className={styles.forgotPassLink} onClick={handleRecoveryLinkClick}>
              {t('loginModal.resetPassword')}
            </a>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <LinkWrapper onClick={() => onHide()} to={`/register`}>
            {t('loginModal.register')}
          </LinkWrapper>
          <Button className={styles.actionBtn} variant="secondary" type="submit" disabled={isLoading || hasEmptyField}>
            {t('loginModal.btn')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default LoginModal;
