import React, { useState, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import CollapseItem from '../../components/CollapseItem/CollapseItem';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import styles from './FaqPage.module.css';

const FaqPage: React.FC = () => {
  const { t } = useTranslation();
  const [eventKey, setEventKey] = useState('');

  const handleSetEventKey = useCallback(
    (newEventKey: string) => {
      setEventKey(newEventKey === eventKey ? '' : newEventKey);
    },
    [eventKey]
  );
  const link = (
    <LinkWrapper to="/contacts" className={styles.faqLink}>
      {t('FaqPage.answer1Link')}
    </LinkWrapper>
  );

  return (
    <div className={styles.faqPage}>
      <Container>
        <Row>
          <Col>
            <PageTitle>{t('FaqPage.title')}</PageTitle>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.faqList}>
              <div className={styles.faqListContainer}>
                <CollapseItem
                  question="FaqPage.question13"
                  content={
                    <Trans i18nKey="FaqPage.answer13">
                      desc
                      <a rel="noopener noreferrer" target="_blank" href={t('FaqPage.answer13Link')}>
                        {t('FaqPage.answer13Link')}
                      </a>
                      desc
                    </Trans>
                  }
                  eventKey="13"
                  onSetEventKey={handleSetEventKey}
                  show={eventKey === '13'}
                />
                <CollapseItem
                  question="FaqPage.question1"
                  answer="FaqPage.answer1"
                  eventKey="1"
                  onSetEventKey={handleSetEventKey}
                  show={eventKey === '1'}
                  link={link}
                />
                <CollapseItem
                  question="FaqPage.question2"
                  answer="FaqPage.answer2"
                  eventKey="2"
                  onSetEventKey={handleSetEventKey}
                  show={eventKey === '2'}
                />
                <CollapseItem
                  question="FaqPage.question3"
                  answer="FaqPage.answer3"
                  eventKey="3"
                  onSetEventKey={handleSetEventKey}
                  show={eventKey === '3'}
                />
                <CollapseItem
                  question="FaqPage.question4"
                  answer="FaqPage.answer4"
                  important="FaqPage.important4"
                  eventKey="4"
                  onSetEventKey={handleSetEventKey}
                  show={eventKey === '4'}
                />
              </div>
              <div className={styles.faqListContainer}>
                <CollapseItem
                  question="FaqPage.question5"
                  content={
                    <Trans i18nKey="FaqPage.answer5">
                      desc
                      <LinkWrapper to="/">theorie24.ch</LinkWrapper>
                      desc
                    </Trans>
                  }
                  eventKey="5"
                  onSetEventKey={handleSetEventKey}
                  show={eventKey === '5'}
                />
                <CollapseItem
                  question="FaqPage.question6"
                  answer="FaqPage.answer6"
                  eventKey="6"
                  onSetEventKey={handleSetEventKey}
                  show={eventKey === '6'}
                />
                <CollapseItem
                  question="FaqPage.question7"
                  answer="FaqPage.answer7"
                  eventKey="7"
                  onSetEventKey={handleSetEventKey}
                  show={eventKey === '7'}
                />
                <CollapseItem
                  question="FaqPage.question8"
                  answer="FaqPage.answer8"
                  eventKey="8"
                  onSetEventKey={handleSetEventKey}
                  show={eventKey === '8'}
                />
                <CollapseItem
                  question="FaqPage.question9"
                  answer="FaqPage.answer9"
                  eventKey="9"
                  onSetEventKey={handleSetEventKey}
                  show={eventKey === '9'}
                />
              </div>
              <div className={styles.faqListContainer}>
                <CollapseItem
                  question="FaqPage.question10"
                  answer="FaqPage.answer10"
                  eventKey="10"
                  onSetEventKey={handleSetEventKey}
                  show={eventKey === '10'}
                />
                <CollapseItem
                  question="FaqPage.question11"
                  answer="FaqPage.answer11"
                  eventKey="11"
                  onSetEventKey={handleSetEventKey}
                  show={eventKey === '11'}
                />
                <CollapseItem
                  question="FaqPage.question12"
                  answer="FaqPage.answer12"
                  eventKey="12"
                  onSetEventKey={handleSetEventKey}
                  show={eventKey === '12'}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FaqPage;
