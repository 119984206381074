import { createAction } from 'redux-actions';

export const CLEAR_USER = createAction('CLEAR_USER');

export const SET_LANGUAGE_TOGGLE_STATE = createAction('SET_LANGUAGE_TOGGLE_STATE');

export const SET_SELECTED_QUESTION_LANGUAGE = createAction('SET_SELECTED_QUESTION_LANGUAGE');

export const SET_USER_SELECTED_TOPIC = createAction('SET_USER_SELECTED_TOPIC');
export const SET_USER_SELECTED_CATEGORY = createAction('SET_USER_SELECTED_CATEGORY');
export const SET_USER_SELECTED_QUESTION = createAction('SET_USER_SELECTED_QUESTION');
export const SET_USER_QUESTION_LANGUAGE_SUCCESS = createAction('SET_USER_QUESTION_LANGUAGE_SUCCESS');

export const SET_USER_SELECTED_QUESTION_GROUP = createAction('SET_USER_SELECTED_QUESTION_GROUP');

export const SET_USER_LANGUAGE_REQUEST = createAction('SET_USER_LANGUAGE_REQUEST');
export const SET_USER_LANGUAGE_SUCCESS = createAction('SET_USER_LANGUAGE_SUCCESS');
export const SET_USER_LANGUAGE_FAILURE = createAction('SET_USER_LANGUAGE_FAILURE');

export const GET_USER_PROFILE_REQUEST = createAction('GET_USER_PROFILE_REQUEST');
export const GET_USER_PROFILE_FAILURE = createAction('GET_USER_PROFILE_FAILURE');
export const GET_USER_PROFILE_SUCCESS = createAction('GET_USER_PROFILE_SUCCESS');

export const GET_PROFILE_FROM_FIREBASE_REQUEST = createAction('GET_PROFILE_FROM_FIREBASE_REQUEST');
export const GET_PROFILE_FROM_FIREBASE_FAILURE = createAction('GET_PROFILE_FROM_FIREBASE_FAILURE');
export const GET_PROFILE_FROM_FIREBASE_SUCCESS = createAction('GET_PROFILE_FROM_FIREBASE_SUCCESS');

export const GET_USER_EXAM_RESULTS_REQUEST = createAction('GET_USER_EXAM_RESULTS_REQUEST');
export const GET_USER_EXAM_RESULTS_SUCCESS = createAction('GET_USER_EXAM_RESULTS_SUCCESS');
export const GET_USER_EXAM_RESULTS_FAILURE = createAction('GET_USER_EXAM_RESULTS_FAILURE');

export const SET_USER_STATISTICS = createAction('SET_USER_STATISTICS');
export const SET_DEFAULT_USER_PROFILE = createAction('SET_DEFAULT_USER_PROFILE');

export const PLAN_EXTENSION_REQUEST = createAction('PLAN_EXTENSION_REQUEST');
export const PLAN_EXTENSION_SUCCESS = createAction('PLAN_EXTENSION_SUCCESS');
export const PLAN_EXTENSION_FAILURE = createAction('PLAN_EXTENSION_FAILURE');

export const SET_MARKED_QUESTION = createAction('SET_MARKED_QUESTION');

export const SET_USER_FIRST_EXAM_PASSED_REQUEST = createAction('SET_USER_FIRST_EXAM_PASSED_REQUEST');
export const SET_USER_FIRST_EXAM_PASSED_SUCCESS = createAction('SET_USER_FIRST_EXAM_PASSED_SUCCESS');
export const SET_USER_FIRST_EXAM_PASSED_FAILURE = createAction('SET_USER_FIRST_EXAM_PASSED_FAILURE');

export const SAVE_SELECTED_CATEGORY_REQUEST = createAction('SAVE_SELECTED_CATEGORY_REQUEST');
export const SAVE_SELECTED_CATEGORY_SUCCESS = createAction('SAVE_SELECTED_CATEGORY_SUCCESS');
export const SAVE_SELECTED_CATEGORY_FAILURE = createAction('SAVE_SELECTED_CATEGORY_FAILURE');

export const UPDATE_CURRENT_CATEGORY = createAction('UPDATE_CURRENT_CATEGORY');

export const SET_BOOK_MARKED_PAGE_REQUEST = createAction('SET_BOOK_MARKED_PAGE_REQUEST');
export const SET_BOOK_MARKED_PAGE_FAILURE = createAction('SET_BOOK_MARKED_PAGE_FAILURE');
export const SET_BOOK_MARKED_PAGE_SUCCESS = createAction('SET_BOOK_MARKED_PAGE_SUCCESS');
