import alasql from 'alasql';
import { TQuestion } from './question.schema';
import { getFBCategoryName } from '../utils/utils';
import { CategoryType, QuestionGroupOptions, QuestionGroupType } from '../reducer/question.types';
import question from '../db/question.json';

export const getQuestionsByTopic = async (
  topicId: number,
  currentCategory: CategoryType,
  selectedQuestionGroup: QuestionGroupType
) => {
  const asaCondition = selectedQuestionGroup === QuestionGroupOptions.ASA ? 'AND asa = true' : '';
  const query = `
    SELECT * FROM ? 
    WHERE category = ? AND themeid = ? ${asaCondition} 
    ORDER BY sortnr ASC`;

  const allQuestionList: TQuestion[] = alasql(query, [question, getFBCategoryName(currentCategory), topicId]);

  return allQuestionList;
};

export const getQuestionsFromList = async (currentCategory, markedQuestions) => {
  const allQuestionList: TQuestion[] = alasql('SELECT * FROM ? WHERE category = ? ORDER BY sortnr ASC', [
    question,
    getFBCategoryName(currentCategory)
  ]);
  return allQuestionList.filter(({ id }) => markedQuestions.includes(id));
};

export const getDemoQuestions = async (currentCategory: CategoryType) => {
  const allQuestionList: TQuestion[] = alasql('SELECT * FROM ? WHERE category = ? AND demo = ? ORDER BY sortnr ASC', [
    question,
    getFBCategoryName(currentCategory),
    true
  ]);

  return allQuestionList;
};

export const getAllQuestions = async (currentCategory: CategoryType, selectedQuestionGroup = 'ALL') => {
  const asaCondition = selectedQuestionGroup === QuestionGroupOptions.ASA ? ' AND asa = true' : '';
  const query = `
    SELECT * FROM ? 
    WHERE category = ?${asaCondition} 
    ORDER BY sortnr ASC`;

  const allQuestionList: TQuestion[] = alasql(query, [question, getFBCategoryName(currentCategory)]);

  return allQuestionList;
};

export const getQuestions = async (
  topic: number | string,
  currentCategory: CategoryType,
  markedQuestions?,
  // @ts-ignore
  selectedQuestionGroup: QuestionGroupType
) => {
  switch (topic) {
    case 'demo':
      return getDemoQuestions(currentCategory);
    case 'all':
      return getAllQuestions(currentCategory, selectedQuestionGroup);
    case 'marked':
    case 'incorrect':
      return getQuestionsFromList(currentCategory, markedQuestions);
    default:
      return getQuestionsByTopic(+topic, currentCategory, selectedQuestionGroup);
  }
};
