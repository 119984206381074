import { Request } from './fetch.types';

export type CategoryType = 'B' | 'A,A1' | 'F,G' | 'M' | 'BAA1' | '';

export type QuestionGroupType = 'ALL' | 'ASA';

export enum QuestionGroupOptions {
  ALL = 'ALL',
  ASA = 'ASA'
}

export interface ILangsAnswersAttr {
  text: string;
  id: number;
}

export interface ILangsAttributes {
  q: string;
  a: ILangsAnswersAttr[];
}

interface ILangsLanguage {
  [language: string]: ILangsAttributes;
}

export interface IQuestion {
  id: number;
  correct: number[];
  category: CategoryType;
  asa: boolean;
  sort: number;
  nonasa: boolean; // no need for frontend
  theoryid: string; // need for redirect to theory page (or number)
  isdemo: boolean;
  themeid: string; // we don't use `chapternr` name in frontend application (or number)
  img: string;
  langs: ILangsLanguage;
}

export interface IQuestionState {
  questionList: IQuestion[];
  questionListRequest: Request;
}
