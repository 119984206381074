import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { AnimateSharedLayout } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import Cookies from 'js-cookie';
import { GET_TOPICS_REQUEST } from '../../actions/topic.action';
import { GET_ANSWER_FROM_FIREBASE_REQUEST } from '../../actions/answer.action';
import { TopicsAttributes } from '../../reducer/topic.types';
import { IRootState } from '../../reducer';
import { allProgress, incorrectProgress, marketProgress } from '../../components/TopicProgress/TopicProgress';
import { IUserAnswer } from '../../reducer/user.types';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { selectLanguage } from '../../reducer/user.selectors';
import { P3 } from '../../ui-components/P3/P3';
import { ViewTypeButton } from './components/ViewTypeButton/ViewTypeButton';
import { TopicSection, YourProgressSection } from './sections/index.sections';
import { getUsersMarkedQuestions } from '../../selectors/user.selectors';
import TopicItem from './components/TopicItem/TopicItem';
import { QUESTION_PAGE_VIEW_TYPE } from '../../constants/storageKeys';
import useAfterUserDataFetched from '../../hooks/useAfterUserDataFetched';
import { LoaderWrapper } from '../../ui-components/LoaderWrapper/LoaderWrapper';
import { ViewTypeButtonType } from './components/ViewTypeButton/ViewTypeButton.types';
import { QuestionGroupOptions, QuestionGroupType } from '../../reducer/question.types';
import styles from './QuestionsByTopicContainer.module.css';
import SelectedQuestionGroupIcon from '../../components/SelectedQuestionGroupIcon/SelectedQuestionGroupIcon';

const QuestionsByTopicContainer: React.FC = () => {
  const { t } = useTranslation();
  const [selectedTopicId, setSelectedTopicId] = useState<null | string>(null);
  const markedQuestions = useSelector(getUsersMarkedQuestions);
  const topicsALL = useSelector(({ topic }: IRootState) => topic.topics);
  const topicsASA = useSelector(({ topic }: IRootState) => topic.topicsASA);
  const selectedQuestionGroup: QuestionGroupType = useSelector(
    ({ user }: IRootState) => user.profile.selectedQuestionGroup
  );

  const topics = selectedQuestionGroup === QuestionGroupOptions.ASA ? topicsASA : topicsALL;

  const language = useSelector(selectLanguage);
  const currentCategory = useSelector(({ user }: IRootState) => user.profile.currentCategory);
  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);
  const dispatch = useDispatch();
  const category = useSelector(({ user }: IRootState) => user.profile.currentCategory);
  const [isAllDataLoading, setIsAllDataLoadingStatus] = useState(true);

  /** previous saved view type  * */
  const savedViewType =
    Cookies.get(QUESTION_PAGE_VIEW_TYPE) === ViewTypeButtonType.Grid
      ? ViewTypeButtonType.Grid
      : ViewTypeButtonType.List;

  const [currentViewType, setCurrentViewType] = useState<ViewTypeButtonType>(savedViewType);

  const { history } = useReactRouter();
  const userAnswers: IUserAnswer = useSelector(({ answer: { answerList } }: IRootState) => {
    return answerList;
  });

  const handleQuestionChange = useCallback(
    (link: string) => {
      history.push(link);
    },
    [history]
  );

  const handleSetNewViewType = useCallback((type: ViewTypeButtonType) => {
    Cookies.set(QUESTION_PAGE_VIEW_TYPE, type);
    setCurrentViewType(type);
  }, []);

  const isDataAlreadyFetched = useAfterUserDataFetched(() => {
    setIsAllDataLoadingStatus(false);
  });

  useEffect(() => {
    dispatch(GET_TOPICS_REQUEST({ category, language }));
  }, [dispatch, category, language]);

  useEffect(() => {
    dispatch(GET_ANSWER_FROM_FIREBASE_REQUEST());
  }, [dispatch, category]);

  // const hasAnswers = Object.keys(userAnswers).length > 0;

  const renderTopicsList = () => {
    const filterTopic = topics.filter(topic => topic.id !== 'marked' && topic.id !== 'incorrect');

    const accordionClassName = cx(styles.accordion, [{ accordionGrid: currentViewType === ViewTypeButtonType.Grid }]);
    return (
      <div className={`${styles.accordion} ${accordionClassName}`}>
        <AnimateSharedLayout>
          {filterTopic.slice(1, 20).map((topic: TopicsAttributes) => (
            <TopicItem
              key={topic.id}
              onQuestionChange={handleQuestionChange}
              topic={topic}
              userAnswers={userAnswers}
              currentLanguage={language}
              currentCategory={currentCategory}
              selectedTopicId={selectedTopicId}
              setSelectedTopicId={setSelectedTopicId}
              eventKey={topic.id.toString()}
            />
          ))}
        </AnimateSharedLayout>
      </div>
    );
  };

  const allProgressPercent: number = useMemo(() => {
    return Number(allProgress(topics[0], userAnswers, selectedQuestionGroup)?.progress / 100).toFixed(2)
      ? +Number(allProgress(topics[0], userAnswers, selectedQuestionGroup)?.progress / 100).toFixed(2)
      : 0;
  }, [topics, userAnswers, selectedQuestionGroup]);

  const incorrectAnswerCount: number = useMemo(() => {
    const topicsIncorrect = topics.find(topic => topic.id === 'incorrect');
    return incorrectProgress(topicsIncorrect, userAnswers, selectedQuestionGroup).correctCount;
  }, [topics, userAnswers, selectedQuestionGroup]);

  const markedCount = useMemo(() => {
    const topicsMarked = topics.find(topic => topic.id === 'marked');
    return (
      topicsMarked &&
      marketProgress(topicsMarked, userAnswers, markedQuestions, currentCategory, selectedQuestionGroup).questionsCount
    );
  }, [topics, userAnswers, markedQuestions, currentCategory, selectedQuestionGroup]);

  const correctAnswer = useMemo(() => {
    return allProgress(topics[0], userAnswers, selectedQuestionGroup).correctCount;
  }, [topics, userAnswers, selectedQuestionGroup]);

  const fasadList = useMemo(
    () => ({
      all: allProgressPercent,
      marked: markedCount,
      incorrect: incorrectAnswerCount,
      correct: correctAnswer
    }),
    [allProgressPercent, incorrectAnswerCount, markedCount, correctAnswer]
  );

  const getStatisticCountByType = useCallback(
    (type: 'all' | 'marked' | 'incorrect' | 'correct') => {
      if (topics.length > 0) {
        return fasadList[type];
      }
      return 0;
    },
    [topics, fasadList]
  );

  const showLoaderWrapper = isLogged ? isAllDataLoading && !isDataAlreadyFetched : false;

  const disableClickForDemo = useMemo(() => {
    return { pointerEvents: isLogged ? ('auto' as 'auto') : ('none' as 'none') };
  }, [isLogged]);

  return (
    <div className={styles.questionSection}>
      <Container>
        <Row>
          <Col>
            <div className={styles.questionsByTopic} style={disableClickForDemo}>
              <PageTitle className={styles.pageTitle}>
                {t('questionsByTopicContainer.yourProgress')}
                <SelectedQuestionGroupIcon classes={styles.questionGroupIconContainer} />
              </PageTitle>
              <LoaderWrapper show={showLoaderWrapper} classStyles={styles.loaderWrapper}>
                <YourProgressSection
                  currentLanguage={language}
                  currentCategory={currentCategory}
                  correctAnswerCount={getStatisticCountByType('correct')}
                  allProgressPercent={getStatisticCountByType('all')}
                  incorrectCount={getStatisticCountByType('incorrect')}
                  markedCount={getStatisticCountByType('marked')}
                />
                <TopicSection />
                <ViewTypeButton
                  containerClass={styles.toggleViewButton}
                  type={currentViewType}
                  setNewTypeCb={handleSetNewViewType}
                />
                <div className={cx(styles.questionsByTopicList)}>{renderTopicsList()}</div>
              </LoaderWrapper>
              <div className={styles.questionsTopicText}>
                <P3>{t('questionsByTopicContainer.descriptionLeft')}</P3>
                <P3>{t('questionsByTopicContainer.descriptionCenter')}</P3>
                <P3>{t('questionsByTopicContainer.descriptionRight')}</P3>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default React.memo(QuestionsByTopicContainer);
